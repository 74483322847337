body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  border       : 16px solid #f3f3f3;
  border-top   : 16px solid #3498db;
  border-radius: 50%;
  width        : 130px;
  height       : 130px;
  animation    : spin 2s linear infinite;
}

.loader-container {
  width           : 100vw;
  height          : 100vh;
  position        : fixed;
  z-index         : 2700;
  display         : flex;
  justify-content : center;
  align-items     : center;
  background-color: rgba(76, 102, 172, 0.5);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}